@import './styles/variables';
@import 'node_modules/bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
@import 'libs/fe/ui-ng/scss/abstracts/variables.scss';
@import './styles/color';
@import 'libs/fe/ui-ng/scss/style.scss';

@import '~@angular/material/theming';

@include mat-core();

$md-fimm: (
    50 : #e0ebe4,
    100 : #b3cebc,
    200 : #80ad90,
    300 : #4d8c63,
    400 : #267341,
    500 : #005a20, //primary color from variables.scss
    600 : #00521c,
    700 : #004818,
    800 : #003f13,
    900 : #002e0b,
    A100 : #67ff7a,
    A200 : #34ff4e,
    A400 : #01ff22,
    A700 : #00e61e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//computed complementary colors
$md-fimmaccent: (
    50 : #e0e7eb,
    100 : #b3c4ce,
    200 : #809dad,
    300 : #4d768c,
    400 : #265873,
    500 : #003b5a,
    600 : #003552,
    700 : #002d48,
    800 : #00263f,
    900 : #00192e,
    A100 : #67a7ff,
    A200 : #3489ff,
    A400 : #016bff,
    A700 : #0061e6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$fimm-app-primary: mat-palette($md-fimm);
$fimm-app-accent:  mat-palette($md-fimmaccent, A200, A100, A400);
$fimm-app-theme:   mat-light-theme((
  color: (
    primary: $fimm-app-primary,
    accent: $fimm-app-accent,
  )
));

@include angular-material-theme($fimm-app-theme);
